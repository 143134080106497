import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  Box,
  useMediaQuery,
  IconButton,
  Drawer,
  // Grid,
} from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
// import PhoneIcon from "@mui/icons-material/Phone";
// import MailIcon from "@mui/icons-material/Mail";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:600px) and (max-width:800px)");

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const navigations = ["home", "benifits", "services", "about", "contact"];
  const drawerContent = (
    <Box
      sx={{
        width: "auto",
        padding: 2,
        py: 10,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {navigations.map((section, index) => (
        <ScrollLink
          key={section}
          to={section}
          smooth={true}
          duration={500}
          offset={-70}
          onClick={toggleDrawer}
          style={{
            cursor: "pointer",
            color: "inherit",
            textDecoration: "none",
            padding: 10,
            display: "block",
          }}
        >
          <Typography variant="h6" align="center">
            {t(section)}
          </Typography>
        </ScrollLink>
      ))}
      <Select
        value={i18n.language}
        onChange={handleLanguageChange}
        variant="outlined"
        size="small"
        sx={{ minWidth: 50, mx: "auto" }}
      >
        <MenuItem value="en">EN</MenuItem>
        <MenuItem value="de">DE</MenuItem>
      </Select>
    </Box>
  );

  return (
    <>
      {/* <Grid
        container
        sx={{
          background: "#191919",
          minHeight: "5vh",
          px: { xs: 2, sm: 8, md: 10, lg: 30 },
          py: 1,
          alignItems: "center",
          justifyContent: { xs: "center", sm: "space-between" },
          textAlign: { xs: "center", sm: "left" },
          display: {
            xs: "none", // Applies to extra small and small screens
            sm: "flex", // Applies to medium and larger screens
          },
        }}
      >
        <Grid
          item
          color="#a6b1b7"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
          xs={12}
          sm={6}
        >
          <PhoneIcon />
          <Typography sx={{ ml: 1 }}>
            <a
              href="tel:+9192365 48885"
              style={{ textDecoration: "none", color: "#a6b1b7" }}
            >
              +91 92365 48885
            </a>
          </Typography>
        </Grid>
        <Grid
          item
          color="#a6b1b7"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", sm: "flex-end" },
            gap: 2,
          }}
          xs={12}
          sm={6}
        >
          <MailIcon />
          <Typography>
            <a
              href="mailto:sales@kernit-innovations.com"
              style={{ textDecoration: "none", color: "#a6b1b7" }}
            >
              sales@kernit-innovations.com
            </a>
          </Typography>
        </Grid>
      </Grid> */}

      <AppBar
        position="sticky"
        color="default"
        elevation={1}
        sx={{
          background: "white",
          // borderBottom: "1px solid #ddd",
          px: { sm: 0, md: 10, lg: 20 },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt="Company Logo"
              style={{
                maxWidth: "100%",
                height: "auto",
                width: isMobile ? 80 : isTablet ? 100 : 130, // Adjust width based on screen size
              }}
            />
          </Box>

          {/* Tablet View Layout Adjustment */}
          {isTablet || isMobile ? (
            <>
              {/* Right-aligned Hamburger Menu */}
              <IconButton onClick={toggleDrawer} color="inherit">
                <MenuIcon sx={{ fontSize: 30 }} />
              </IconButton>
            </>
          ) : isMobile ? (
            // Mobile View Layout
            <IconButton onClick={toggleDrawer} color="inherit">
              <MenuIcon sx={{ fontSize: 30 }} />
            </IconButton>
          ) : (
            // Desktop View Layout
            <Box sx={{ display: "flex", gap: 4 }}>
              {navigations.map((section) => (
                <ScrollLink
                  key={section}
                  to={section}
                  smooth={true}
                  duration={500}
                  offset={-100}
                  style={{
                    cursor: "pointer",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    letterSpacing={1}
                    lineHeight={3}
                    noWrap
                  >
                    {t(section)}
                  </Typography>
                </ScrollLink>
              ))}
            </Box>
          )}

          {/* Language Selector for Desktop View */}
          {!isTablet && !isMobile && (
            <Select
              value={i18n.language}
              onChange={handleLanguageChange}
              variant="outlined"
              size="small"
              sx={{ minWidth: 50, marginLeft: 1 }}
            >
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="de">DE</MenuItem>
            </Select>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile/Tablet View */}
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;
