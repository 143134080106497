import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

function AboutUs({ contactFormHeight }) {
  const { t } = useTranslation();
  return (
    <Element name="about">
      <Box
        sx={{
          maxWidth: "100vw",
          // height: contactFormHeight ? `${contactFormHeight}px` : "auto",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          // alignItems: "center",
          position: "relative",
          paddingLeft: { xs: 2, sm: 8, lg: 30 },
          // paddingRight: { xs: 2, md: 0 },
          pr: { xs: 2, sm: 8, md: 10, lg: 30 },

          mb: 10,
        }}
      >
        <Box sx={{ py: { xs: 2, sm: 3, md: 5 } }}>
          <Typography
            variant="h4"
            fontWeight={400}
            mb={1}
            noWrap
            sx={{
              fontSize: {
                xs: "1.2rem", // For extra-small screens (e.g., mobile)
                sm: "1.5rem", // For small screens (e.g., tablets)
                md: "1.8rem", // For medium screens (e.g., small laptops)
              },
              // p: 0,
            }}
          >
            {t("about_title_1")}
          </Typography>
          <Box
            sx={{
              width: "20vh",
              background: "#079dff",
              height: "0.5vh",
              borderRadius: "10px",
              mb: 3,
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            // height: "100%",
          }}
        >
          <Typography mb={2} variant="body2">
            {" "}
            {t("about_description_1.1")}
          </Typography>
          <Typography variant="body2"> {t("about_description_1.2")}</Typography>
          <Typography mt={2} variant="body2">
            {" "}
            {t("about_description_1.3")}
          </Typography>
        </Box>
      </Box>
    </Element>
  );
}

export default AboutUs;
