import React from "react";
import { Element } from "react-scroll";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();

  const images = Array.from({ length: 3 }, (_, index) => ({
    src: require(`/src/assets/service-${index + 1}.svg`),
  }));

  return (
    <Element name="services">
      <Box
        sx={{
          maxWidth: "100vw",
          position: "relative",
          background: "#fefefe",
          mb: 5,
        }}
      >
        <Box display="flex" justifyContent="center" marginBottom={3}>
          <Typography variant="h5" fontWeight={500}>
            {t("service_heading")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            px: { xs: 2, sm: 8, md: 10, lg: 30 },
          }}
        >
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
          >
            {images.map((image, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "400px", // Adjust height as needed
                }}
              >
                <Card
                  sx={{
                    width: 400,
                    // width: "100%",
                    mx: "auto",
                    borderRadius: 2,
                    padding: 1,
                    minHeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #F2F5F9",
                  }}
                  elevation={0}
                >
                  {image && (
                    <CardMedia
                      component="img"
                      sx={{
                        height: 100,
                        width: 100,
                        borderRadius: "50%",
                        objectFit: "fill",
                        alignSelf: "flex-start", // Align image to top
                        mt: 2,
                        mx: "auto",
                        border: "1px solid #ffdfdf",
                      }}
                      image={image.src}
                      alt={t(`service_title_${index + 1}`)}
                    />
                  )}
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around", // Text aligns to bottom
                      minHeight: "35vh",
                    }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {t(`service_title_${index + 1}`)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" my={1}>
                      {t(`service_description_${index + 1}`)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Element>
  );
}

export default Services;
