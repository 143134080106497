import React from "react";
import { Element } from "react-scroll";
import { Box, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
import theme from "assets/Theme";

function Home() {
  const { t } = useTranslation();

  const images = Array.from({ length: 3 }, (_, index) => ({
    src: require(`/src/assets/consulting-${index + 1}.jpg`),
  }));

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      {/* Fullscreen Image Carousel */}
      <Element name="home">
        <Box sx={{ maxWidth: "100vw", position: "relative" }}>
          <Carousel
            showThumbs={true}
            showStatus={false}
            showIndicators={true}
            infiniteLoop
            autoPlay
            interval={5000}
            stopOnHover={false}
          >
            {images.map((image, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: "100vw",
                  height: {
                    xs: "50vh",
                    md: "93vh", // Applies to extra small and small screens
                    // sm: "88vh", // Applies to medium and larger screens
                  },
                }}
              >
                {/* Image */}
                <Box
                  component="img"
                  src={image.src}
                  alt={`Slide ${index + 1}`}
                  sx={{
                    width: "100vw",
                    objectFit: "cover",
                    height: "100%",
                  }}
                />

                {/* Text Overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%", // Background spans the full width
                    height: "100%", // Background spans the full height
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Full overlay color
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" }, // Center text on small screens, align left on larger
                    alignItems: "center", // Vertically center content
                  }}
                >
                  {/* Text Container */}
                  <Box
                    sx={{
                      maxWidth: { lg: "800px" }, // Fixed width for large screens
                      padding: { xs: 3, sm: 5, md: 8 }, // Responsive padding
                      width: "100%", // Allows responsiveness
                      textAlign: { xs: "center", md: "left" }, // Center text for small screens, left-align for large
                      color: theme.palette.grey[100],
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: {
                          xs: "1rem", // Small screens
                          sm: "1.5rem", // Small-medium screens
                          md: "2rem", // Medium screens
                          lg: "2.5rem", // Large screens
                          xl: "3rem", // Extra-large screens
                        },
                        fontWeight: 600, // Bold heading
                        lineHeight: 1.2,
                        mb: 2, // Add margin below heading
                      }}
                    >
                      {t(`home_heading_caption_${index + 1}`)}
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "0.8rem", // Small screens
                          sm: "1rem", // Small-medium screens
                          md: "1.2rem", // Medium screens
                          lg: "1.5rem", // Large screens
                          xl: "1.8rem", // Extra-large screens
                        },
                        // fontWeight: 100, // Regular weight for description
                        lineHeight: 1.4,
                        mt: 1, // Add margin above description
                      }}
                    >
                      {t(`home_heading_description_${index + 1}`)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Element>
    </Box>
  );
}

export default Home;
