import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import WATesting from "../assets/WebAppTesting.jpg";
import { Element } from "react-scroll";

function WebAppTesting() {
  const { t } = useTranslation();

  const testingItems = Array.from({ length: 5 }, (_, index) => ({
    title: t(`wa_testing_title_${index + 1}`), // Translated title
    description: t(`wa_testing_description_${index + 1}`), // Translated description
  }));
  return (
    <Element name="benifits">
      <Box display="flex" justifyContent="center">
        <Typography variant="h5" fontWeight={500}>
          {t("wa_testing_heading")}
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          py: 3,
          px: { xs: 2, sm: 8, lg: 30 },
          pb: 5,
          gap: { xs: 5, md: 0 },

          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          alignItems={"center"}
          direction={"column"}
          sx={{ pr: 2 }}
          order={{ xs: 2, md: 1 }}
        >
          <List>
            {testingItems.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block", mb: 2 }}
              >
                {/* Title as bold with bullet */}
                <Typography variant="body1" fontWeight="bold">
                  &#8226; {item.title}
                </Typography>
                {/* Description without bullet */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ pl: 3 }}
                >
                  {item.description}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={WATesting}
            alt="Benifits"
            sx={{
              maxWidth: { xs: "100%", md: "95%" },
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Element>
  );
}

export default WebAppTesting;
