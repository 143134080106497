import React, { useRef, useState, useEffect } from "react";
import Header from "component/Header";
import Home from "component/Home";
import Services from "component/Services";
import AboutUs from "component/AboutUs";
// import ContactUs from "component/ContactUs";
import Footer from "component/Footer";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import MapNavigation from "component/MapNavigation";
import Benifits from "component/Benifits";
import MobAppTesting from "component/MobAppTesting";
import WebAppTesting from "component/WebAppTesting";

function App() {
  const contactUsRef = useRef(null);
  const [contactFormHeight, setContactFormHeight] = useState(0);

  useEffect(() => {
    if (contactUsRef.current) {
      setContactFormHeight(contactUsRef.current.offsetHeight);
    }
  }, [contactUsRef]);
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Home />
      <Benifits />
      <Services />
      <Grid container sx={{ background: "#f5fefe" }} spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h5" fontWeight={500}>
              {t("about_heading")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AboutUs contactFormHeight={contactFormHeight} />
        </Grid>
        {/* <Grid item sm={12} md={6}>
          <AboutUs contactFormHeight={contactFormHeight} />
        </Grid>
        <Grid item sm={12} md={6} ref={contactUsRef}>
          {/* <ContactUs /> 
        </Grid> */}
      </Grid>
      {/* <MapNavigation /> */}
      <MobAppTesting />
      <WebAppTesting />
      <Footer />
    </>
  );
}

export default App;
