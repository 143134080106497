import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import benifits from "../assets/benifits-1.jpg";
import { Element } from "react-scroll";

function Benifits() {
  const { t } = useTranslation();
  return (
    <Element name="benifits">
      <Box display="flex" justifyContent="center">
        <Typography variant="h5" fontWeight={500}>
          {t("benifits_heading")}
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          py: 3,
          px: { xs: 2, sm: 8, lg: 30 },
          pb: 5,
          gap: { xs: 5, md: 0 },
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={benifits}
            alt="Benifits"
            sx={{
              maxWidth: { xs: "100%", md: "95%" },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography mb={2} variant="body2">
            {t("benifits_description_1.1")}
          </Typography>
          <Typography variant="body2">
            {t("benifits_description_1.2")}
          </Typography>
          <Typography variant="body2">
            {t("benifits_description_1.3")}
          </Typography>
        </Grid>
      </Grid>
    </Element>
  );
}

export default Benifits;
